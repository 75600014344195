
export const FantomAssets = [
  {
    symbol: 'FTM',
    name: 'Fantom',
    address: ''
  },
  {
    symbol: 'WFTM',
    derivedSymbol: 'FTM',
    name: 'Wrapped Fantom',
    address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83'
  }
];
