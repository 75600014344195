import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { Utils } from "../../utils/Utils";
import { DashboardWallet } from "./DashboardWallet";

export const DashboardTotalCard = ({ eventKey, state }: any) => {
  const toggleAccordion = useAccordionButton(eventKey);

  if (!state?.total) {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <div className="flex items-start flex-wrap justify-between mx-auto mt-8 mb-8 cursor-pointer" onClick={toggleAccordion}>
          <div className="stats-widget">
            <div className="flex items-center flex-wrap lg:flex-nowrap">
              <div className="rounded-8 p-20 bg-basic-1 relative overflow-hidden w-full mb-20 lg:mb-0 lg:mr-20 card-balances-total">
                <h5 className="text-basic-7 font-semibold text-primary-2">Total em BRL</h5>
                <img src="icon-money.svg" width="42" height="42" alt="" className="absolute select-none img-money" />
                <div className="relative">
                  <span className="text-24 leading-24 font-bold text-white">
                    {Utils.valorEmReaisFormatado(state.total.totalBRL)}
                  </span>
                </div>
              </div>
              <div className="rounded-8 p-20 bg-basic-1 relative overflow-hidden w-full mb-20 lg:mb-0 card-balances-total">
                <h5 className="text-basic-7 font-semibold text-primary-2">Total em USD</h5>
                <img src="icon-money.svg" width="42" height="42" alt="" className="absolute select-none img-money" />
                <div className="relative">
                  <span className="text-24 leading-24 font-bold text-white">
                    ${Utils.valorFormatado(state.total.totalUSD)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <DashboardWallet key={"total-card"} state={state} showParticipation={true} hideTotal={true} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
