
export const FtxAssets = [
  {
    symbol: "MANA",
    name: "Decentraland"
  },
  {
    symbol: "AURY",
    name: "Aurory"
  }
];
