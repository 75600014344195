import { ConfigurationService } from "../services/ConfigurationService";

export class BinanceBaseApi {

	protected getHeaders(): any {
		return new Headers({
			'X-MBX-APIKEY': ConfigurationService.getApiKey(),
		});
	}

	protected getSignature(queryString: string): string {
		const CryptoJS = require("crypto-js");
		return CryptoJS.HmacSHA256(queryString, ConfigurationService.getApiSecret()).toString();
	}

  protected async getData(url: string, headers?: Headers): Promise<any> {
    const request = new Request(url, {
			method: 'GET',
			headers: headers ? headers : undefined
		});

		return fetch(request)
			.then(response => {
				return response.json();
			});
  }
}
