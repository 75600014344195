import { normalize as fileNormalize } from "path";
import { InspectOptions, format, inspect } from "util";
import { ICodeFrame, IErrorObject, ILogObject, ISettings, ISettingsParam, IStackFrame, Logger } from "tslog";
import { IFullDateTimeFormatPart } from "tslog/dist/types/interfaces";
import { ILoggerSettings } from "../dtos/ILoggerSettings";
import { LoggerTransportBase } from "./LoggerTransportBase";
import { ILoggerSettingsTransport } from "../dtos/ILoggerSettingsTransport";
import { LoggerTransportConsole } from "./LoggerTransportConsole";
import { LoggerTransportRollbar } from "./LoggerTransportRollbar";

export class cLogger extends Logger {
  private static _logger: cLogger;
  readonly TRANSPORT_CONSOLE = 'console';
  readonly TRANSPORT_FILE = 'file';
  readonly TRANSPORT_ROLLBAR = 'rollbar';

  private loggerSettings: ILoggerSettings;
  private transports: LoggerTransportBase[];
  private _maskValuesOfKeysRegExpExtension: RegExp | undefined;
  private _maskAnyRegExpExtension: RegExp | undefined;

  static createLogger(settings: any): cLogger {
    cLogger._logger = new cLogger(settings);
    return cLogger._logger;
  }

  static getCurrentLogger() {
    if (!cLogger._logger) {
      let defaultLoggerConfig;
      const isBrowser = typeof window !== 'undefined';

      if (isBrowser) {
        defaultLoggerConfig = {
          "name": "default",
          "suppressStdOutput": true,
          "transports": [
            {
              "provider": "console",
              "minLevel": "debug",
              "enabled": true
            }
          ]
        };
      } else {
        defaultLoggerConfig = {
          "name": "default",
          "minLevel": "debug",
          "suppressStdOutput": false,
          "transports": []
        };
      }
      return cLogger.createLogger(defaultLoggerConfig);
    }
    return cLogger._logger;
  }

  constructor(settings?: ISettingsParam, parentSettings?: ISettings) {
        super(settings, parentSettings);
        this.loggerSettings = this.settings as ILoggerSettings;
        this.transports = [];

        this.init();
        this.initTransports();
    }

  async wait() {
    await Promise.all(
      this.transports.map((transport) => transport.wait())
    );
  }

  init() {
        this._maskValuesOfKeysRegExpExtension =
          this.settings.maskValuesOfKeys?.length > 0
          ? new RegExp(
              "^(.[^']*)(" +
                Object.values(this.settings.maskValuesOfKeys).join(
                  ".[^\\w_)].*:|"
                ) +
                ".[^\\w_)].*:).*(\\,?)$",
              "gim"
            )
          : undefined;

        // this._maskAnyRegExpExtension =
        //   this.settings.maskAny?.length > 0
        //     ? new RegExp(Object.values(this.settings.maskAny).join("|"), "g")
        //     : undefined;
    }

  initTransports() {
        if (this.loggerSettings.transports) {
            this.loggerSettings.transports.forEach((transport: ILoggerSettingsTransport) => {
                if (transport.enabled) {
                    switch (transport.provider) {
                      case this.TRANSPORT_CONSOLE:
                          this.attachTransportConsole(transport);
                          break;
                        case this.TRANSPORT_ROLLBAR:
                            this.attachTransportRollbar(transport);
                            break;
                    }
                }
            });
        }
    }

  attachTransportConsole(settings: ILoggerSettingsTransport) {
          const transport = new LoggerTransportConsole(this, settings.options);

          this.attachTransport(
              {
                silly: transport.save.bind(transport),
                debug: transport.save.bind(transport),
                trace: transport.save.bind(transport),
                info: transport.save.bind(transport),
                warn: transport.save.bind(transport),
                error: transport.save.bind(transport),
                fatal: transport.save.bind(transport)
              },
              settings.minLevel
          );

          this.transports.push(transport);
      }

  attachTransportRollbar(settings: ILoggerSettingsTransport) {
        const transport = new LoggerTransportRollbar(this, settings.options);

        this.attachTransport(
            {
              silly: transport.save.bind(transport),
              debug: transport.save.bind(transport),
              trace: transport.save.bind(transport),
              info: transport.save.bind(transport),
              warn: transport.save.bind(transport),
              error: transport.save.bind(transport),
              fatal: transport.save.bind(transport)
            },
            settings.minLevel
        );

        this.transports.push(transport);
    }

  getPrettyLog(logObject: ILogObject, hideDateTime?: boolean, hideLogLevel?: boolean) {
        let log = '';

        if (this.settings.displayDateTime === true && !hideDateTime) {
          const dateTimeParts: IFullDateTimeFormatPart[] = [
            ...(new Intl.DateTimeFormat("en", {
              weekday: undefined,
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              timeZone: this.settings.dateTimeTimezone,
            }).formatToParts(logObject.date) as IFullDateTimeFormatPart[]),
            {
              type: "millisecond",
              value: logObject.date.getMilliseconds().toString(),
            } as IFullDateTimeFormatPart,
          ];

          const nowStr = dateTimeParts.reduce(
            (prevStr, thisStr) => prevStr?.replace(thisStr.type, thisStr.value),
            this.settings.dateTimePattern
          );

          log += `${nowStr}\t`;
        }

        if (this.settings.displayLogLevel && !hideLogLevel) {
          log += ` ${logObject.logLevel.toUpperCase()} \t`;
        }

        const loggerName: string =
          this.settings.displayLoggerName === true && logObject.loggerName != null
            ? logObject.loggerName
            : "";

        const instanceName: string =
          this.settings.displayInstanceName === true &&
          this.settings.instanceName != null
            ? `@${this.settings.instanceName}`
            : "";

        const traceId: string =
          this.settings.displayRequestId === true && logObject.requestId != null
            ? `:${logObject.requestId}`
            : "";

        const name: string =
          (loggerName + instanceName + traceId).length > 0
            ? loggerName + instanceName + traceId
            : "";

        const functionName: string =
          this.settings.displayFunctionName === true
            ? logObject.isConstructor
              ? ` ${logObject.typeName}.constructor`
              : logObject.methodName != null
              ? ` ${logObject.typeName}.${logObject.methodName}`
              : logObject.functionName != null
              ? ` ${logObject.functionName}`
              : ""
            : "";

        let fileLocation = "";
        if (
          this.settings.displayFilePath === "displayAll" ||
          (this.settings.displayFilePath === "hideNodeModulesOnly" &&
            logObject.filePath!.indexOf("node_modules") < 0)
        ) {
          fileLocation = `${logObject.filePath}:${logObject.lineNumber}`;
        }

        const concatenatedMetaLine: string = [name, fileLocation, functionName]
          .join(" ")
          .replace(/\s\s+/g, " ")
          .trim();
        if (concatenatedMetaLine.length > 0) {
          log += `[${concatenatedMetaLine}]  \t`;

          if (this.settings.printLogMessageInNewLine === false) {
            log += "  \t";
          } else {
            log += "\n";
          }
        }

        logObject.argumentsArray.forEach((argument: unknown | IErrorObject) => {
          const typeStr: string =
            this.settings.displayTypes === true
              ? typeof argument + ":" +
                " "
              : "";

          const errorObject: IErrorObject = argument as IErrorObject;
          if (typeof argument === "object" && errorObject.isError === true) {
            log += this.getPrettyError(errorObject);
          } else if (
            typeof argument === "object" &&
            errorObject.isError !== true
          ) {
            log += "\n" + typeStr +
                this._inspectAndHideSensitiveExtension(
                argument,
                this.settings.prettyInspectOptions
                );
          } else {
            log += typeStr + this._formatAndHideSesitiveExtension(argument) + " ";
          }
        });
        log += "\n";

        if (logObject.stack != null) {
          log += "log stack:\n";

          log += this.getPrettyStack(logObject.stack);
        }

        return log;
      }

  private getPrettyError(
        errorObject: IErrorObject,
        printStackTrace = true
      ): string {
        let log = '';

        log += `${errorObject.name}: ` +
            (errorObject.message != null
              ? `\t${this._formatAndHideSesitiveExtension(errorObject.message)}`
              : "")
        ;

        if (Object.values(errorObject.details).length > 0) {
            log += "\ndetails:";
            log += "\n" +
              this._inspectAndHideSensitiveExtension(
                errorObject.details,
                this.settings.prettyInspectOptions
              );
        }

        if (printStackTrace === true && errorObject.stack.length > 0) {
            log += "\nerror stack:";

            log += this.getPrettyStack(errorObject.stack);
        }
        if (errorObject.codeFrame != null) {
            log += this.getPrettyCodeFrame(errorObject.codeFrame);
        }

        return log;
      }

  getPrettyStack(stackObjectArray: IStackFrame[]): string {
        let log = '';

        log += "\n";
        Object.values(stackObjectArray).forEach((stackObject: IStackFrame) => {
            log += "• at ";

            log += (stackObject.functionName ? stackObject.functionName : '');

            if (
                stackObject.filePath != null &&
                stackObject.lineNumber != null &&
                stackObject.columnNumber != null
                ) {
                log += " (";
                log += fileNormalize(`${stackObject.filePath} line ${stackObject.lineNumber} col ${stackObject.columnNumber}`);
                log += ")";
            }

            log += "\n\n";
        });

        return log;
      }

  getPrettyCodeFrame(codeFrame: ICodeFrame): string {
        let log = '';

        log += "code frame:\n";

        let lineNumber: number = codeFrame.firstLineNumber;
        codeFrame.linesBefore.forEach((line: string) => {
            log += `  ${lineNumber} | ${line}\n`;
            lineNumber++;
        });

        log += ">" +
            " " +
            lineNumber +
            " | " +
            codeFrame.relevantLine +
            "\n";

        lineNumber++;

        if (codeFrame.columnNumber != null) {
          const positionMarker: string =
            new Array(codeFrame.columnNumber + 8).join(" ") + `^`;
          log += positionMarker + "\n";
        }

        codeFrame.linesAfter.forEach((line: string) => {
            log += `  ${lineNumber} | ${line}\n`;
            lineNumber++;
        });

        return log;
      }

  private _inspectAndHideSensitiveExtension(
        object: unknown,
        options: InspectOptions
      ): string {
        let inspectedString: string = inspect(object, options);

        if (this._maskValuesOfKeysRegExpExtension != null) {
          inspectedString = inspectedString.replace(
            this._maskValuesOfKeysRegExpExtension,
            "$1$2: " +
            `'${this.settings.maskPlaceholder}'` +
            "$3"
          );
        }

        return this._maskAnyRegExpExtension != null
          ? inspectedString.replace(
              this._maskAnyRegExpExtension,
              this.settings.maskPlaceholder
            )
          : inspectedString;
      }

  private _formatAndHideSesitiveExtension(
        formatParam: unknown,
        ...param: unknown[]
      ): string {
        const formattedStr: string = format(formatParam, ...param);
        return this._maskAnyRegExpExtension != null
          ? formattedStr.replace(this._maskAnyRegExpExtension, this.settings.maskPlaceholder)
          : formattedStr;
      }
}
