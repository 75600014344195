import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { IDashboard } from '../../dtos/IDashboard';
import { ConfigurationService } from '../../services/ConfigurationService';
import { DashboardService } from '../../services/DashboardService';
import { Utils } from '../../utils/Utils';
import { DashboardBalances } from './DashboardBalances';
import { DashboardConfiguration, useConfiguration } from './DashboardConfiguration';
// import { DashboardStaking } from './DashboardStaking';

export const Dashboard = (props: any) => {
  const { configurationState, openModal, closeModal, setStateProperty, pushStateProperty, setStateItemProperty, removeStateItemProperty, saveConfiguration } = useConfiguration(props);
  const { loading, state, setDashboardState, setConfiguration, getClassMenu, selectTab, activeTab } = useDashboard(props);
  return (
    <>
    <div className="px-16 mb-36 mx-auto div-principal">
      <div className="top-0 py-12 flex items-center justify-between w-full mx-auto">
          <div className="flex items-center">
              <ul className="items-center lg:flex">
                <li className={getClassMenu('dashboard')} onClick={e => selectTab(e, 'dashboard')}>Dashboard</li>
                {/* <li className={getClassMenu('staking')} onClick={e => selectTab(e, 'staking')}>Staking</li> */}
              </ul>
          </div>
          <div className="flex justify-end">
              <ul className="justify-end lg:flex">
                  <li className="rounded-8 text-primary-5 bg-primary-1 mr-4">
                    <button className="MuiButtonBase-root MuiIconButton-root jss20 MuiIconButton-colorInherit p-3" onClick={openModal} type="button" aria-label="Configurações" title="Configurações">
                      <span className="MuiIconButton-label">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="MuiSvgIcon-root p-3" viewBox="0 0 16 16"><path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/><path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/></svg>
                      </span>
                    </button>
                  </li>
                  {loading ?
                    <li className="rounded-8 text-primary-5 bg-primary-1 menu-item-circular-progress">
                      <div className="MuiCircularProgress-root app-loader jss19 MuiCircularProgress-indeterminate p-3" role="progressbar" style={{width: '24px', height: '24px'}}>
                        <svg className="MuiCircularProgress-svg" viewBox="22 22 44 44"><circle className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate" cx="44" cy="44" r="19" fill="none" strokeWidth="6"></circle></svg>
                      </div>
                    </li>
                  :
                    <li className="rounded-8 text-primary-5 bg-primary-1">
                      <button className="MuiButtonBase-root MuiIconButton-root jss20 MuiIconButton-colorInherit p-3" onClick={setDashboardState} type="button" aria-label="Atualizar" title="Atualizar">
                        <span className="MuiIconButton-label">
                          <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                        </span>
                      </button>
                    </li>
                  }
              </ul>
          </div>
      </div>

      <Tabs activeKey={activeTab} className="mb-3 nav-tabs-title-hidden">
        <Tab eventKey="dashboard" title="">
          <DashboardBalances state={state} />
        </Tab>
        {/* <Tab eventKey="staking" title="">
          <DashboardStaking state={state} />
        </Tab> */}
      </Tabs>
    </div>
    <DashboardConfiguration
      state={configurationState}
      open={openModal}
      close={closeModal}
      save={saveConfiguration}
      setState={setStateProperty}
      pushState={pushStateProperty}
      setStateItem={setStateItemProperty}
      removeStateItem={removeStateItemProperty}
      onSave={setConfiguration}
    />
    </>
  );
};

export const useDashboard = (props: any): any => {
  const defaultTitle = 'Staking Dashboard';
  const [timer, setTimer] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(defaultTitle);
  const [state, setState] = useState<IDashboard>();
  const [dashboardService] = useState<DashboardService>(new DashboardService());
  const [activeTab, setActiveTab] = useState('dashboard');
  const activeTabRef = useRef('dashboard');

  function setDashboardState() {
    if (loading) {
      return;
    }

    setLoading(true);

    dashboardService.getDashboard().then(data => {
      if (data) {
        setState(data);
        setWindowTitle(activeTabRef.current, data);
      }
      setLoading(false);
    });
  }

  function setWindowTitle(tab: string, data: any) {
    if (data?.stakingAXS && data?.stakingTotal && data?.total) {
      if (tab === 'dashboard') {
        setTitle(`$${Utils.valorFormatado(data.stakingAXS.stats.price.usd)} | ${Utils.valorFormatado(data.total.totalBRL)} | ${defaultTitle}`);
      } else {
        setTitle(`$${Utils.valorFormatado(data.stakingAXS.stats.price.usd)} | ${Utils.valorFormatado(data.stakingTotal.totalBRL)} | ${defaultTitle}`);
      }
    }
  }

  function setTimerInterval() {
    if (timer) {
      clearInterval(timer);
    }

    const timerId = setInterval(() => {
      setDashboardState();
    }, ConfigurationService.getIntervalo() * 1000);

    setTimer(timerId);
  }

  function setConfiguration() {
    setDashboardState();
    setTimerInterval();
  }

  function getClassMenu(tab: string) {
    if (activeTab === tab) {
      return 'mr-12 px-16 rounded-8 text-gray-8 font-semibold cursor-pointer menu-item text-primary-5 bg-primary-1';
    }
    return 'mr-12 px-16 rounded-8 text-gray-8 font-semibold cursor-pointer menu-item';
  };

  function selectTab(event: any, tab: string) {
    event.preventDefault();
    setActiveTab(tab);
    setWindowTitle(tab, state);
  };

  useEffect(() => {
    activeTabRef.current = activeTab;
	});

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setDashboardState();
    setTimerInterval();
    // eslint-disable-next-line
	}, []);

  return {
    timer,
    loading,
    title,
    state,
    activeTab,
    setTimer,
    setLoading,
    setTitle,
    setState,
    setDashboardState,
    setTimerInterval,
    setConfiguration,
    setActiveTab,
    setWindowTitle,
    getClassMenu,
    selectTab,
  };
};
