
export class ConfigurationService {
  private static readonly CONFIG_KEY = 'config';

  static getIntervalo(): number {
    const config = this.getConfig();
    if (config) {
      return config.intervalo;
    }
    return 60;
  }

  static getRoninAddress() {
    const config = this.getConfig();
    if (config) {
      return config.address;
    }
    return null;
  }

  static getMetaMaskAddresses(): string[] {
    const config = this.getConfig();
    if (config) {
      return config.metaMaskAddresses;
    }
    return [];
  }

  static getApiKey() {
    const config = this.getConfig();
    if (config) {
      return config.apiKey;
    }
    return null;
  }

  static getApiSecret() {
    const config = this.getConfig();
    if (config) {
      return config.apiSecret;
    }
    return null;
  }

  static getFtxApiKey() {
    const config = this.getConfig();
    if (config) {
      return config.ftxApiKey;
    }
    return null;
  }

  static getFtxApiSecret() {
    const config = this.getConfig();
    if (config) {
      return config.ftxApiSecret;
    }
    return null;
  }

  static setConfig(value: any) {
    localStorage.setItem(this.CONFIG_KEY, JSON.stringify(value));
  }

  static getConfig(): any {
    const storedConfig = localStorage.getItem(this.CONFIG_KEY);
    if (storedConfig) {
      return JSON.parse(storedConfig);
    }
    return null;
  }
}
