import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { ConfigurationService } from '../../services/ConfigurationService';

export const DashboardConfiguration = (props: any) => {

  function saveClick() {
    if (props.save) {
      props.save();
    }

    if (props.onSave) {
      props.onSave();
    }
  }

  function addMetaMaskAddress() {
    if (props.pushState) {
      props.pushState('metaMaskAddresses', '');
    }
  }

  function removeMetaMaskAddress(index: number) {
    if (props.removeStateItem) {
      props.removeStateItem('metaMaskAddresses', index);
    }
  }

  return (
    <Modal
      show={props.state.modalIsOpen}
      onHide={props.close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Configurações</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-4" controlId="intervalo">
            <Form.Label>Intervalo (Seg)</Form.Label>
            <Form.Control type="number" value={props.state.intervalo} onInput={(e: any) => props.setState('intervalo', e.target.value)} />
          </Form.Group>
          <hr />
          {/* <Form.Group className="mb-4" controlId="address">
            <Form.Label>Ronin Address</Form.Label>
            <Form.Control type="text" value={props.state.address} onInput={(e: any) => props.setState('address', e.target.value)} />
          </Form.Group>
          <hr /> */}
          <Form.Label className="font-bold">MetaMask Addresses</Form.Label>
          <Button variant="secondary" className="font-bold" style={{float: 'right'}} onClick={addMetaMaskAddress}>
            Add
          </Button>
          <hr />
          {props.state.metaMaskAddresses.map((address: string, index: number) => (
            <Form.Group className="mb-4" controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" value={address} onInput={(e: any) => props.setStateItem('metaMaskAddresses', index, e.target.value)} />
              <Button variant="secondary" className="font-bold" style={{float: 'right'}} onClick={() => { removeMetaMaskAddress(index) }}>X</Button>
            </Form.Group>
          ))}
          <Form.Label className="font-bold">Binance</Form.Label>
          <hr />
          <Form.Group className="mb-4" controlId="apiKey">
            <Form.Label>Api Key</Form.Label>
            <Form.Control type="text" value={props.state.apiKey} onInput={(e: any) => props.setState('apiKey', e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-4" controlId="apiSecret">
            <Form.Label>Api Secret</Form.Label>
            <Form.Control type="password" value={props.state.apiSecret} onInput={(e: any) => props.setState('apiSecret', e.target.value)} />
          </Form.Group>

          {/* <Form.Label className="font-bold">FTX</Form.Label>
          <hr />
          <Form.Group className="mb-4" controlId="ftxApiKey">
            <Form.Label>Api Key</Form.Label>
            <Form.Control type="text" value={props.state.ftxApiKey} onInput={(e: any) => props.setState('ftxApiKey', e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-4" controlId="ftxApiSecret">
            <Form.Label>Api Secret</Form.Label>
            <Form.Control type="password" value={props.state.ftxApiSecret} onInput={(e: any) => props.setState('ftxApiSecret', e.target.value)} />
          </Form.Group> */}
          <hr />
          {/* <Form.Group className="mb-4">
            <Form.Label>Investido AXS</Form.Label>
            <div className="flex">
              <Form.Control className="col-6" type="number" placeholder="BRL" value={props.state.totalInvestedAXS_BRL} onInput={(e: any) => props.setState('totalInvestedAXS_BRL', e.target.value)} />
              <Form.Control className="col-6" type="number" placeholder="USD" value={props.state.totalInvestedAXS_USD} onInput={(e: any) => props.setState('totalInvestedAXS_USD', e.target.value)} />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Investido SLP-WETH LP</Form.Label>
            <div className="flex">
              <Form.Control className="col-6" type="number" placeholder="BRL" value={props.state.totalInvestedSLPWETH_BRL} onInput={(e: any) => props.setState('totalInvestedSLPWETH_BRL', e.target.value)} />
              <Form.Control className="col-6" type="number" placeholder="USD" value={props.state.totalInvestedSLPWETH_USD} onInput={(e: any) => props.setState('totalInvestedSLPWETH_USD', e.target.value)} />
            </div>
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Fechar
        </Button>
        <Button variant="primary" onClick={saveClick}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const useConfiguration = (props: any): any => {
  const [state, setState] = useState<any>(() => ({
		modalIsOpen: false,
    intervalo: 60,
    metaMaskAddresses: []
	}));

  function openModal() {
    const config = ConfigurationService.getConfig();
    setState({
			...state,
			...config,
      modalIsOpen: !state.modalIsOpen,
		});
  }

  function closeModal() {
    setState({
			...state,
      modalIsOpen: !state.modalIsOpen,
		});
  }

  function setStateProperty(property: string, value: any): void {
		if (value) {
			setState({
				...state,
				[property]: value,
			});
		}
	}

  function pushStateProperty(property: string, value: any): void {
		setState({
      ...state,
      [property]: [...state[property], value],
    });
	}

  function setStateItemProperty(property: string, index: number, value: any): void {
		if (value) {
      state[property][index] = value;
			setState({
				...state,
				[property]: state[property],
			});
		}
	}
  
  function removeStateItemProperty(property: string, index: number): void {
		if (index >= 0) {
      const newArray = [...state[property]];
      newArray.splice(index, 1);
			setState({
				...state,
				[property]: newArray,
			});
		}
	}

  function saveConfiguration(): void {
    ConfigurationService.setConfig(state);
    closeModal();
	}

  useEffect(() => {
    const config = ConfigurationService.getConfig();

    if (!config?.metaMaskAddresses?.length
      && !config?.apiKey) {
      openModal();
    }
    // eslint-disable-next-line
	}, []);

  return {
    configurationState: state,
    openModal,
    closeModal,
    setStateProperty,
    pushStateProperty,
    setStateItemProperty,
    removeStateItemProperty,
    saveConfiguration,
  };
};
