import { AllAssets } from "../assets/AllAssets";
import { Provider } from "../enums/Provider";
import { CoinGeckoApi } from "../providers/CoinGeckoApi";
import { CoinMarketCapApi } from "../providers/CoinMarketCapApi";
import { logger } from "../utils/Logger";
import { BalanceService } from "./BalanceService";
import { ConfigurationService } from "./ConfigurationService";
import { TickerService } from "./TickerService";

export class DashboardBinanceSmartChainService {

  constructor(
    private readonly tickerService: TickerService,
    private readonly balanceService: BalanceService,
    private readonly coinGeckoApi: CoinGeckoApi,
    private readonly coinMarketCapApi: CoinMarketCapApi,
  ) { }

  async getDashboard(): Promise<any> {
    try {
      const config = ConfigurationService.getConfig();

      if (!config?.metaMaskAddresses?.length) {
        return null;
      }

      const usdTicker = await this.tickerService.getTicker24h('USDTBRL');
      const assetBalances = this.balanceService.getAllBalances(Provider.BINANCE_SMART_CHAIN);
      const vsCurrency = 'USDT';
      let totalUSD = 0;

      for (const asset of assetBalances) {
        let ticker = null;

        if (asset.symbol === 'GQ' || asset.symbol === 'SAFUU') {
          ticker = await this.coinMarketCapApi.getTicker24h(asset.symbol);
        } else {
          ticker = await this.tickerService.getTicker24h(`${asset.symbol}${vsCurrency}`);
        }

        if (ticker) {
          asset.price = ticker.lastPrice;
          asset.priceChangePercent = ticker.priceChangePercent;
          asset.totalUSD = asset.balance * asset.price;
          asset.totalBRL = asset.totalUSD * usdTicker.lastPrice;

          totalUSD += asset.totalUSD;
        }

        if (!asset.name) {
          const assetFind = AllAssets.find(p => p.symbol === asset.symbol);
          asset.name = assetFind?.name;
        }
      }

      return {
        balances: assetBalances,
        total: {
          totalUSD,
          totalBRL: totalUSD * usdTicker.lastPrice
        }
      };
    } catch (error) {
      logger.error('Erro ao carregar dashboard binance smart chain', error);
    }
  }
}
