import { v4 as uuidv4 } from "uuid";
import { ConfigurationService } from "../services/ConfigurationService";
import { logger } from "../utils/Logger";
import { Utils } from "../utils/Utils";
import { BaseRpcApi } from "./BaseRpcApi";

export class EthereumRpcApi extends BaseRpcApi {
  protected assets: any[];

  constructor(urlRpc: string, assets: any[]) {
    super(urlRpc);
    this.assets = assets;
  }

  async getAllBalances(): Promise<any[]> {
    const balances = [];
    try {
      const addresses = ConfigurationService.getMetaMaskAddresses();

      if (addresses?.length > 0) {
        for (const address of addresses) {
          const addressBalances = await this.getBalancesFromAddress(address);
          balances.push(...addressBalances);
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos', error);
    }

    return balances;
  }

  async getBalancesFromAddress(address: string): Promise<any[]> {
    const balances = [];
    try {
      if (address) {
        const listRequests = [];

        for (const asset of this.assets) {
          const request = asset.address
            ? this.getBalanceOf(asset.address, address, asset.decimals)
            : this.getBalance(address);

          listRequests.push(request);
        }

        const result = await Promise.all(listRequests);

        for (let i = 0; i < this.assets.length; i++) {
          const token = this.assets[i];
          const balance = result[i];

          balances.push({
            ...token,
            balance
          });
        }
      }
    } catch (error) {
      logger.error(`Erro ao carregar saldos para o endereço: ${address}`, error);
    }

    return balances;
  }

  async getBalance(address: string): Promise<number> {
    const payload = this.buildCallGetBalance(address);
    const response = await this.postData(payload);
    return Utils.getNumberFromRpc(response);
  }

  async getBalanceOf(contractAddress: string, address: string, decimals?: number): Promise<number> {
    const payload = this.buildCallBalanceOf(contractAddress, address);
    const response = await this.postData(payload);
    return Utils.getNumberFromRpc(response, decimals);
  }

  private buildCallGetBalance(address: string): string {
    return JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method: 'eth_getBalance',
      params: [
        `0x${address.replace('0x', '')}`,
        'latest'
      ]
    });
  }

  private buildCallBalanceOf(contractAddress: string, address: string): string {
    return JSON.stringify({
      id: uuidv4(),
      jsonrpc: '2.0',
      method: 'eth_call',
      params: [
        {
          to: contractAddress,
          data: `0x70a08231000000000000000000000000${address.replace('0x', '')}`
        },
        'latest'
      ]
    });
  }
}
