import { Accordion } from "react-bootstrap";
// import { DashboardStakingCard } from "./DashboardStakingCard";
import { DashboardTotalCard } from "./DashboardTotalCard";
import { DashboardWalletCard } from "./DashboardWalletCard";
import { Utils } from "../../utils/Utils";

export const DashboardBalances = (props: any) => {
  const state = props?.state;

  if (!state) {
    return null;
  }

  return (<>
    <div className="mt-8 w-full text-center">
      <h1 className="font-semibold lg:text-32 lg:leading-44 text-20 leading-28  inline-flex items-center">
        <span className="mx-12 lg:mx-8">Dashboard</span>
      </h1>
      <br />
      {state?.updateTime ?
      <span className="ds-tag minimal text-10">
        Atualizado em: {state.updateTime.toLocaleString()}
      </span>
      : null}
    </div>

    <div className="flex items-start flex-wrap justify-between mx-auto mt-8 mb-8">
      <div className="stats-widget">
        <div className="flex items-center flex-wrap lg:flex-nowrap">
          <div className="rounded-8 bg-basic-1 relative px-8 pt-4 pb-0" style={{minWidth: '100%'}}>
            <div className="flex items-center flex-wrap">
              <div className="flex-1 mb-8">
                  <div className="uppercase font-bold leading-16 text-basic-7 value-title">Valor BTC (USD)</div>
                  <div>
                      <div className="flex items-center">
                          <span className="text-12 leading-24 font-bold">${Utils.valorFormatado(state.ticker.btcUsdPrice)}</span>
                          {state.ticker.btcUsdPricePercentChange >= 0 ?
                              <span className="ds-tag intent-success ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 7H15V5h7v7h-2V8.414l-7 7-4-4-5.293 5.293a1 1 0 01-1.414-1.414L9 8.586l4 4L18.586 7z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(state.ticker.btcUsdPricePercentChange)}%
                              </span>
                          :
                              <span className="ds-tag intent-danger ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 15H15v2h7v-7h-2v3.586l-7-7-4 4-5.293-5.293a1 1 0 00-1.414 1.414L9 13.414l4-4L18.586 15z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(Math.abs(state.ticker.btcUsdPricePercentChange))}%
                              </span>
                          }
                      </div>
                  </div>
              </div>
              <div className="flex-1 mb-8">
                  <div className="uppercase font-bold leading-16 text-basic-7 value-title">Valor BTC (BRL)</div>
                  <div>
                      <div className="flex items-center">
                          <span className="text-12 leading-24 font-bold">{Utils.valorEmReaisFormatado(state.ticker.btcBrlPrice)}</span>
                          {state.ticker.btcBrlPricePercentChange >= 0 ?
                              <span className="ds-tag intent-success ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 7H15V5h7v7h-2V8.414l-7 7-4-4-5.293 5.293a1 1 0 01-1.414-1.414L9 8.586l4 4L18.586 7z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(state.ticker.btcBrlPricePercentChange)}%
                              </span>
                          :
                              <span className="ds-tag intent-danger ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 15H15v2h7v-7h-2v3.586l-7-7-4 4-5.293-5.293a1 1 0 00-1.414 1.414L9 13.414l4-4L18.586 15z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(Math.abs(state.ticker.btcBrlPricePercentChange))}%
                              </span>
                          }
                      </div>
                  </div>
              </div>
              <div className="flex-1 mb-8">
                  <div className="uppercase font-bold leading-16 text-basic-7 value-title">Valor ETH (USD)</div>
                  <div>
                      <div className="flex items-center">
                          <span className="text-12 leading-24 font-bold">${Utils.valorFormatado(state.ticker.ethUsdPrice)}</span>
                          {state.ticker.ethUsdPricePercentChange >= 0 ?
                              <span className="ds-tag intent-success ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 7H15V5h7v7h-2V8.414l-7 7-4-4-5.293 5.293a1 1 0 01-1.414-1.414L9 8.586l4 4L18.586 7z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(state.ticker.ethUsdPricePercentChange)}%
                              </span>
                          :
                              <span className="ds-tag intent-danger ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 15H15v2h7v-7h-2v3.586l-7-7-4 4-5.293-5.293a1 1 0 00-1.414 1.414L9 13.414l4-4L18.586 15z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(Math.abs(state.ticker.ethUsdPricePercentChange))}%
                              </span>
                          }
                      </div>
                  </div>
              </div>
              <div className="flex-1 mb-8">
                  <div className="uppercase font-bold leading-16 text-basic-7 value-title">Valor ETH (BRL)</div>
                  <div>
                      <div className="flex items-center">
                          <span className="text-12 leading-24 font-bold">{Utils.valorEmReaisFormatado(state.ticker.ethBrlPrice)}</span>
                          {state.ticker.ethBrlPricePercentChange >= 0 ?
                              <span className="ds-tag intent-success ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 7H15V5h7v7h-2V8.414l-7 7-4-4-5.293 5.293a1 1 0 01-1.414-1.414L9 8.586l4 4L18.586 7z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(state.ticker.ethBrlPricePercentChange)}%
                              </span>
                          :
                              <span className="ds-tag intent-danger ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 15H15v2h7v-7h-2v3.586l-7-7-4 4-5.293-5.293a1 1 0 00-1.414 1.414L9 13.414l4-4L18.586 15z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(Math.abs(state.ticker.ethBrlPricePercentChange))}%
                              </span>
                          }
                      </div>
                  </div>
              </div>
              <div className="flex-1 mb-8">
                  <div className="uppercase font-bold leading-16 text-basic-7 value-title">Valor USD (BRL)</div>
                  <div>
                      <div className="flex items-center">
                          <span className="text-12 leading-24 font-bold">{Utils.valorEmReaisFormatado(state.ticker.usdBrlPrice)}</span>
                          {state.ticker.usdBrlPricePercentChange >= 0 ?
                              <span className="ds-tag intent-success ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 7H15V5h7v7h-2V8.414l-7 7-4-4-5.293 5.293a1 1 0 01-1.414-1.414L9 8.586l4 4L18.586 7z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(state.ticker.usdBrlPricePercentChange)}%
                              </span>
                          :
                              <span className="ds-tag intent-danger ml-4 minimal text-11">
                                  <span className="left-icon">
                                      <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 15H15v2h7v-7h-2v3.586l-7-7-4 4-5.293-5.293a1 1 0 00-1.414 1.414L9 13.414l4-4L18.586 15z" fill=""></path></svg>
                                  </span>
                                  {Utils.valorFormatado(Math.abs(state.ticker.usdBrlPricePercentChange))}%
                              </span>
                          }
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Accordion defaultActiveKey={[]} className="mt-8 accordion-balances" alwaysOpen>
      <DashboardTotalCard eventKey="0" state={state} />
      {/* <DashboardStakingCard eventKey="1" state={state} title="Staking" /> */}
      <DashboardWalletCard eventKey="2" state={state.binanceSmartChain} title="Binance Smart Chain" />
      <DashboardWalletCard eventKey="3" state={state.binance} title="Binance" />
      {/* <DashboardWalletCard eventKey="4" state={state.avalanche} title="Avalanche" /> */}
      {/* <DashboardWalletCard eventKey="5" state={state.ftx} title="FTX" /> */}
      <DashboardWalletCard eventKey="6" state={state.ethereum} title="Ethereum" />
      {/* <DashboardWalletCard eventKey="7" state={state.fantom} title="Fantom" />
      <DashboardWalletCard eventKey="8" state={state.zkSync} title="zkSync" /> */}
    </Accordion>
    </>
  );
};
