import { BinanceApi } from "../providers/BinanceApi";
import { logger } from "../utils/Logger";
import { ConfigurationService } from "./ConfigurationService";
import { TickerService } from "./TickerService";

export class DashboardBinanceService {

  constructor(
    private readonly tickerService: TickerService,
    private readonly binanceApi: BinanceApi
  ) { }

  async getDashboard(): Promise<any> {
    try {
      const config = ConfigurationService.getConfig();

      if (!config?.apiKey
      && !config?.apiSecret) {
        return null;
      }

      const usdTicker = await this.tickerService.getTicker24h('USDTBRL');
      const assetBalances = await this.binanceApi.getAllAccountBalances();
      const vsCurrency = 'USDT';
      let totalUSD = 0;

      for (const asset of assetBalances) {
        const ticker = await this.tickerService.getTicker24h(`${asset.symbol}${vsCurrency}`);

        if (ticker && asset.symbol !== vsCurrency) {
          asset.price = ticker.lastPrice;
          asset.priceChangePercent = ticker.priceChangePercent;
          asset.totalUSD = asset.balance * asset.price;
          asset.totalBRL = asset.totalUSD * usdTicker.lastPrice;
        } else if (asset.symbol === vsCurrency) {
          asset.price = 1;
          asset.priceChangePercent = 0;
          asset.totalUSD = asset.balance * asset.price;
          asset.totalBRL = asset.totalUSD * usdTicker.lastPrice;
        }

        if (!asset.hide) {
          totalUSD += asset.totalUSD;
        }
      }

      return {
        balances: assetBalances,
        total: {
          totalUSD,
          totalBRL: totalUSD * usdTicker.lastPrice
        }
      };
    } catch (error) {
      logger.error('Erro ao carregar dashboard binance', error);
    }
  }
}
