
export const RoninAssets = [
  {
    symbol: 'RON',
    name: 'Ronin',
    address: ''
  },
  {
    symbol: 'AXS',
    name: 'Axie Infinity',
    address: '0x97a9107c1793bc407d6f527b77e7fff4d812bece'
  },
  {
    symbol: 'SLP',
    name: 'Smooth Love Potion',
    address: '0xa8754b9fa15fc18bb59458815510e40a12cd2014'
  },
  {
    symbol: 'WETH',
    derivedSymbol: 'ETH',
    name: 'Ronin Wrapped Ether',
    address: '0xc99a6a985ed2cac1ef41640596c5a5f9f4e19ef5'
  },
  {
    symbol: 'AXIE',
    name: 'Axie',
    address: '0x32950db2a7164ae833121501c797d79e7b79d74c',
    decimals: 0
  },
  {
    symbol: 'SLP-WETH LP',
    name: 'SLP-WETH Liquidity Provider',
    address: '0x306a28279d04a47468ed83d55088d0dcd1369294'
  }
];
