import { IDashboard } from "../dtos/IDashboard";
import { logger } from "../utils/Logger";
import { BinanceApi } from "../providers/BinanceApi";
// import { BinanceFuturesApi } from "../providers/BinanceFuturesApi";
import { CoinGeckoApi } from "../providers/CoinGeckoApi";
// import { FtxApi } from "../providers/FtxApi";
// import { KatanaApi } from "../providers/KatanaApi";
// import { RoninApi } from "../providers/RoninApi";
import { TickerService } from "./TickerService";
import { BalanceService } from "./BalanceService";
import { Provider } from "../enums/Provider";
import { DashboardBinanceService } from "./DashboardBinanceService";
// import { DashboardFtxService } from "./DashboardFtxService";
import { DashboardBinanceSmartChainService } from "./DashboardBinanceSmartChainService";
// import { DashboardAvalancheService } from "./DashboardAvalancheService";
import { DashboardEthereumService } from "./DashboardEthereumService";
// import { DashboardStakingAxsService } from "./DashboardStakingAxsService";
// import { DashboardStakingSlpWethService } from "./DashboardStakingSlpWethService";
import { AllAssets } from "../assets/AllAssets";
// import { DashboardZkSyncService } from "./DashboardZkSyncService";
// import { DashboardFantomService } from "./DashboardFantomService";
import { CoinMarketCapApi } from "../providers/CoinMarketCapApi";

export class DashboardService {
  private readonly binanceApi: BinanceApi;
  // private readonly ftxApi: FtxApi;
  // private readonly roninApi: RoninApi;
  // private readonly katanaApi: KatanaApi;
  private readonly coinGeckoApi: CoinGeckoApi;
  // private readonly binanceFuturesApi: BinanceFuturesApi;
  private readonly tickerService: TickerService;
  private readonly balanceService: BalanceService;
  private readonly coinMarketCapApi: CoinMarketCapApi;

  private readonly dashboardBinanceService: DashboardBinanceService;
  // private readonly dashboardFtxService: DashboardFtxService;
  private readonly dashboardBinanceSmartChainService: DashboardBinanceSmartChainService;
  // private readonly dashboardAvalancheService: DashboardAvalancheService;
  private readonly dashboardEthereumService: DashboardEthereumService;
  // private readonly dashboardStakingAxsService: DashboardStakingAxsService;
  // private readonly dashboardStakingSlpWethService: DashboardStakingSlpWethService;
  // private readonly dashboardZkSyncService: DashboardZkSyncService;
  // private readonly dashboardFantomService: DashboardFantomService;

  constructor() {
    this.balanceService = new BalanceService();
    this.binanceApi = new BinanceApi(this.balanceService);
    // this.ftxApi = new FtxApi(this.balanceService);
    // this.roninApi = new RoninApi();
    // this.katanaApi = new KatanaApi(this.roninApi);
    this.coinGeckoApi = new CoinGeckoApi();
    this.coinMarketCapApi = new CoinMarketCapApi();
    // this.binanceFuturesApi = new BinanceFuturesApi();
    this.tickerService = new TickerService(this.binanceApi);
    this.dashboardBinanceService = new DashboardBinanceService(this.tickerService, this.binanceApi);
    // this.dashboardFtxService = new DashboardFtxService(this.tickerService, this.ftxApi);
    this.dashboardBinanceSmartChainService = new DashboardBinanceSmartChainService(this.tickerService, this.balanceService, this.coinGeckoApi, this.coinMarketCapApi);
    // this.dashboardAvalancheService = new DashboardAvalancheService(this.tickerService, this.balanceService, this.coinGeckoApi, this.coinMarketCapApi);
    this.dashboardEthereumService = new DashboardEthereumService(this.tickerService, this.balanceService, this.coinGeckoApi, this.coinMarketCapApi);
    // this.dashboardStakingAxsService = new DashboardStakingAxsService(this.tickerService, this.roninApi, this.binanceFuturesApi);
    // this.dashboardStakingSlpWethService = new DashboardStakingSlpWethService(this.tickerService, this.katanaApi, this.roninApi, this.coinGeckoApi, this.coinMarketCapApi, this.balanceService);
    // this.dashboardZkSyncService = new DashboardZkSyncService(this.tickerService, this.balanceService);
    // this.dashboardFantomService = new DashboardFantomService(this.tickerService, this.balanceService);
  }

  async getDashboard(): Promise<IDashboard | null> {
    try {
      await Promise.all([
        this.tickerService.updateTickers(),
        this.balanceService.updateBalances(),
      ]);

      const result = await Promise.all([
        // this.dashboardStakingAxsService.getDashboard(),
        // this.dashboardStakingSlpWethService.getDashboard(),
        this.dashboardBinanceService.getDashboard(),
        // this.dashboardFtxService.getDashboard(),
        this.dashboardBinanceSmartChainService.getDashboard(),
        // this.dashboardAvalancheService.getDashboard(),
        this.dashboardEthereumService.getDashboard(),
        // this.dashboardZkSyncService.getDashboard(),
        // this.dashboardFantomService.getDashboard(),
        this.tickerService.getTicker24h('BTCUSDT'),
        this.tickerService.getTicker24h('BTCBRL'),
        this.tickerService.getTicker24h('ETHUSDT'),
        this.tickerService.getTicker24h('ETHBRL'),
        this.tickerService.getTicker24h('USDTBRL'),
      ]);

      // const stakingAXS = result[0];
      // const stakingSLPWETH = result[1];
      const binance = result[0];
      // const ftx = result[3];
      const binanceSmartChain = result[1];
      // const avalanche = result[4];
      const ethereum = result[2];
      // const zkSync = result[6];
      // const fantom = result[7];
      const btcUsdTicker = result[3];
      const btcBrlTicker = result[4];
      const ethUsdTicker = result[5];
      const ethBrlTicker = result[6];
      const usdBrlTicker = result[7];

      // const stakingTotal = this.buildTotalStaking(stakingAXS, stakingSLPWETH);

      const stakingTotal = null;
      const avalanche = null;
      const zkSync = null;
      const fantom = null;

      const total = this.buildTotalGeral(stakingTotal, binance, binanceSmartChain, avalanche, ethereum, zkSync, fantom);
      const balances = await this.buildSaldosGeral(total);
      const btcUsdPrice = Number(btcUsdTicker.lastPrice);
      const btcUsdPricePercentChange = Number(btcUsdTicker.priceChangePercent);
      const btcBrlPrice = Number(btcBrlTicker.lastPrice);
      const btcBrlPricePercentChange = Number(btcBrlTicker.priceChangePercent);
      const ethUsdPrice = Number(ethUsdTicker.lastPrice);
      const ethUsdPricePercentChange = Number(ethUsdTicker.priceChangePercent);
      const ethBrlPrice = Number(ethBrlTicker.lastPrice);
      const ethBrlPricePercentChange = Number(ethBrlTicker.priceChangePercent);
      const usdBrlPrice = Number(usdBrlTicker.lastPrice);
      const usdBrlPricePercentChange = Number(usdBrlTicker.priceChangePercent);
      return {
        ticker: {
          btcUsdPrice,
          btcUsdPricePercentChange,
          btcBrlPrice,
          btcBrlPricePercentChange,
          ethUsdPrice,
          ethUsdPricePercentChange,
          ethBrlPrice,
          ethBrlPricePercentChange,
          usdBrlPrice,
          usdBrlPricePercentChange,
        },
        // stakingAXS,
        // stakingSLPWETH,
        binance,
        // ftx,
        binanceSmartChain,
        // avalanche,
        ethereum,
        // zkSync,
        // fantom,
        // stakingTotal,
        balances,
        total,
        updateTime: new Date()
      }
    } catch (error) {
      logger.error('Erro ao carregar dashboard', error);
    }

    return null;
  }

  private buildTotalStaking(stakingAXS: any, stakingSLPWETH: any): any {
    try {
      const totalInvestedBRL = (stakingAXS?.myStats?.total?.totalInvestedBRL || 0) + (stakingSLPWETH?.myStats?.total?.totalInvestedBRL || 0);
      const totalInvestedUSD = (stakingAXS?.myStats?.total?.totalInvestedUSD || 0) + (stakingSLPWETH?.myStats?.total?.totalInvestedUSD || 0);
      const totalUSD = (stakingAXS?.myStats?.total?.totalUSD || 0) + (stakingSLPWETH?.myStats?.total?.totalUSD || 0);
      const percentUSD = ((totalUSD / totalInvestedUSD) -1) * 100;
      const totalBRL = (stakingAXS?.myStats?.total?.totalBRL || 0) + (stakingSLPWETH?.myStats?.total?.totalBRL || 0);
      const percentBRL = ((totalBRL / totalInvestedBRL) -1) * 100;
      return {
        totalInvestedBRL,
        totalInvestedUSD,
        totalBRL,
        percentBRL,
        totalUSD,
        percentUSD
      };
    } catch (error) {
      logger.error('Erro ao calcular total staking', error);
    }
  }

  private async buildSaldosGeral(total: any) {
    const usdTicker = await this.tickerService.getTicker24h('USDTBRL');
    const allBalances = this.balanceService.getAllBalances();
    const vsCurrency = 'USDT';
    const balances = [];

    const allBalancesGrouped = allBalances.reduce((r: any, item) => {
      if (!r[item.symbol]) {
        r[item.symbol] = {
          ...item,
          provider: [item.provider]
        };
      } else {
        r[item.symbol].balance += item.balance;
        r[item.symbol].provider.push(item.provider);
      }

      return r;
    }, {});

    for (const key of Object.keys(allBalancesGrouped)) {
      const item = allBalancesGrouped[key];

      if (item.price) {
        item.totalUSD = item.balance * item.price;
        item.totalBRL = item.totalUSD * usdTicker.lastPrice;
        item.percentParticipation = (item.totalBRL / total.totalBRL) * 100;
      } else if (item.symbol === vsCurrency) {
        item.price = 1;
        item.priceChangePercent = 0;
        item.totalUSD = item.balance * item.price;
        item.totalBRL = item.totalUSD * usdTicker.lastPrice;
        item.percentParticipation = (item.totalBRL / total.totalBRL) * 100;
      } else {
        let ticker = await this.tickerService.getTicker24h(`${item.symbol}${vsCurrency}`);
        // const isPossuiProviderFtx = item.provider.some((p: any) => p === Provider.FTX);
        const isPossuiProviderAvalanche = item.provider.some((p: any) => p === Provider.AVALANCHE);
        const isPossuiProviderRonin = item.provider.some((p: any) => p === Provider.RONIN);
        const isPossuiAxie = item.symbol === 'AXIE';

        if (!ticker?.lastPrice) {
          if (isPossuiAxie) {
            ticker = await this.tickerService.getTicker24h(`${item.symbol}${vsCurrency}`);
          // } else if (isPossuiProviderFtx) {
          //   ticker = await this.tickerService.getTicker24h(`${item.symbol}/USD`);
          } else if (isPossuiProviderAvalanche || isPossuiProviderRonin) {
            ticker = await this.coinMarketCapApi.getTicker24h(item.symbol);
          }
        }

        if (ticker) {
          item.name = ticker.name;
          item.price = ticker.lastPrice;
          item.priceChangePercent = ticker.priceChangePercent;
          item.totalUSD = item.balance * item.price;
          item.totalBRL = item.totalUSD * usdTicker.lastPrice;
          item.percentParticipation = (item.totalBRL / total.totalBRL) * 100;
        }
      }

      if (item.totalUSD >= 0.01) {
        if (!item.name) {
          const asset = AllAssets.find(p => p.symbol === item.symbol);
          item.name = asset?.name;
        }

        balances.push(item);
      }
    }

    return balances;
  }

  private buildTotalGeral(stakingTotal: any, binance: any, binanceSmartChain: any, avalanche: any, ethereum: any, zkSync: any, fantom: any): any {
    try {
      const totalUSD = (stakingTotal?.totalUSD || 0) +
                        (binance?.total?.totalUSD || 0) +
                        // (ftx?.total?.totalUSD || 0) +
                        (binanceSmartChain?.total?.totalUSD || 0) +
                        (avalanche?.total?.totalUSD || 0) +
                        (ethereum?.total?.totalUSD || 0) +
                        (zkSync?.total?.totalUSD || 0) +
                        (fantom?.total?.totalUSD || 0);

      const totalBRL = (stakingTotal?.totalBRL || 0) +
                        (binance?.total?.totalBRL || 0) +
                        // (ftx?.total?.totalBRL || 0) +
                        (binanceSmartChain?.total?.totalBRL || 0) +
                        (avalanche?.total?.totalBRL || 0) +
                        (ethereum?.total?.totalBRL || 0) +
                        (zkSync?.total?.totalBRL || 0) +
                        (fantom?.total?.totalBRL || 0);
      return {
        totalBRL,
        totalUSD
      };
    } catch (error) {
      logger.error('Erro ao calcular total geral', error);
    }
  }
}
