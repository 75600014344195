
export class BaseRpcApi {
  private urlRpc: string;

  constructor(urlRpc: string) {
    this.urlRpc = urlRpc;
  }

  async postData(data: any): Promise<any> {
    const request = new Request(this.urlRpc, {
			method: 'POST',
			body: data,
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
		});

		return fetch(request)
			.then(response => {
				return response.json();
			});
  }

	async getData(url: string): Promise<any> {
    const request = new Request(url, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
		});

		return fetch(request)
			.then(response => {
				return response.json();
			});
  }
}
