import { ILogObject } from "tslog";
import { cLogger } from "./Logger";

export abstract class LoggerTransportBase {
    constructor(protected instance: cLogger, protected options: any) {
    }

    abstract save(logObject: ILogObject): void;
    abstract wait(): Promise<void>;
}
