import { BinanceAssets } from "../assets/BinanceAssets";
import { Provider } from "../enums/Provider";
import { BalanceService } from "../services/BalanceService";
import { ConfigurationService } from "../services/ConfigurationService";
import { logger } from "../utils/Logger";
import { Utils } from "../utils/Utils";
import { BinanceBaseApi } from "./BinanceBaseApi";

export class BinanceApi extends BinanceBaseApi {
  private readonly URL_TICKER_PRICE = 'https://api.binance.com/api/v3/ticker/price?symbol=';
	private readonly URL_TICKER_24H = 'https://api.binance.com/api/v3/ticker/24hr';
	private readonly URL_ACCOUNT = 'https://api.binance.com/api/v3/account?';

  constructor(
		private readonly balanceService: BalanceService,
	) {
    super();
	}

  async getTickerPrice(symbol: string) {
    const url = `${this.URL_TICKER_PRICE}${symbol}`;
    const response = await this.getData(url);
		return Number(response.price);
  }

  async getAllTickers24h(): Promise<any[]> {
    const config = ConfigurationService.getConfig();

    if (!config?.apiKey
      && !config?.apiSecret
      && !config?.address
      && config?.metaMaskAddresses?.length !== 0) {
      return [];
    }

    return this.getData(this.URL_TICKER_24H);
  }

	async getTicker24h(symbol?: string): Promise<any> {
    const url = `${this.URL_TICKER_24H}${symbol ? '?symbol=' + symbol : ''}`;
    return this.getData(url);
  }

	async getAllAccountBalances(): Promise<any[]> {
    const balances = [];
    try {
      const assetBalances = this.balanceService.getAllBalances(Provider.BINANCE);

      if (assetBalances) {
        for (const token of assetBalances) {

          const asset = BinanceAssets.find(p => p.symbol === token.symbol);
          if (asset) {
            token.name = asset.name;
            token.hide = asset.hide;
          }

          balances.push({
            ...token,
            balance: token.balance || 0,
            totalUSD: 0,
            totalBRL: 0,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos binance', error);
    }

    return balances;
  }

	async getAccountBalances(): Promise<any[]> {
    const config = ConfigurationService.getConfig();

    if (!config?.apiKey
    && !config?.apiSecret) {
      return [];
    }

		const timestamp = Date.now();
		const queryString = `timestamp=${timestamp}`;
		const url = `${Utils.getUrlCORS(this.URL_ACCOUNT)}${queryString}&signature=${this.getSignature(queryString)}`;

		const response = await this.getData(url, this.getHeaders());
		return response.balances;
	}
}
