import { DateTime, Duration } from "luxon";

export class Utils {
  private static readonly DEFAULT_DECIMALS = 18;

  static getNumberFromRpc(response: any, decimals = this.DEFAULT_DECIMALS): number {
    if (!response?.result) {
      return 0;
    }

    return Utils.getNumberFromHex(response.result, decimals);
  }

  static getNumberFromHex(value: string, decimals = this.DEFAULT_DECIMALS): number {
    const valueDecimal = this.getValueFromHex(value);
    return valueDecimal / Math.pow(10, decimals);
  }

  static getValueFromHex(value: string): number {
    const valueNormalized = value.replace('0x', '');
    return parseInt(valueNormalized, 16);
  }

  static getValueToHex(value: number) {
    return value.toString(16);
  }

  static valorFormatado(valor: number, casasDecimais = 2, valorMinimo = 0): string {
    if (valor === undefined) {
      return '';
    }

    if (valorMinimo && !(valor >= valorMinimo)) {
      return '<0,000001';
    }

    return valor.toLocaleString('pt-BR', {
      minimumFractionDigits: casasDecimais,
      maximumFractionDigits: casasDecimais,
    });
  }

  static valorEmReaisFormatado(valor: number, casasDecimais = 2): string {
    if (valor === undefined) {
      return '';
    }

    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: casasDecimais,
      maximumFractionDigits: casasDecimais,
    });
  }

  static valorAbreviadoFormatado(valor: number, casasDecimais = 2) {
    if (valor >= 1000000000) {
      return this.valorFormatado(valor / 1000000000, casasDecimais) + 'b';
    }
    if (valor >= 1000000) {
      return this.valorFormatado(valor / 1000000, casasDecimais) + 'm';
    }
    if (valor >= 1000) {
      return this.valorFormatado(valor / 1000, casasDecimais) + 'k';
    }
    return this.valorFormatado(valor, casasDecimais);
  }

  static valorAbreviadoEmReaisFormatado(valor: number, casasDecimais = 2) {
    if (valor >= 1000000000) {
      return this.valorEmReaisFormatado(valor / 1000000000, casasDecimais) + 'b';
    }
    if (valor >= 1000000) {
      return this.valorEmReaisFormatado(valor / 1000000, casasDecimais) + 'm';
    }
    // if (valor >= 1000) {
    //   return this.valorEmReaisFormatado(valor / 1000, casasDecimais) + 'k';
    // }
    return this.valorEmReaisFormatado(valor, casasDecimais);
  }

  static calculaJurosCompostos(valorPrincipal: number, percentualJuros: number, periodos: number): number {
    return valorPrincipal * Math.pow(1 + (percentualJuros / 100), periodos);
  };

  static calculaPercentualJurosCompostos(valorPrincipal: number, percentualJuros: number, periodos: number): number {
    const valorComJuros = this.calculaJurosCompostos(valorPrincipal, percentualJuros, periodos);
    return ((valorComJuros / valorPrincipal) - 1) * 100;
  };

  static getDataAddDias(date: Date, dias: number): Date {
    const data = DateTime.fromJSDate(date).toUTC();
    return data.plus({ days: dias }).toJSDate();
  }

  static isDataHoraMaior(date1: Date, date2: Date): boolean {
    const data1 = DateTime.fromJSDate(date1).toUTC();
    const data2 = DateTime.fromJSDate(date2).toUTC();
    return data1 > data2;
  }

  static isDataMenor(date1: Date, date2: Date): boolean {
    const data1 = DateTime.fromISO(DateTime.fromJSDate(date1).toUTC().toISODate());
    const data2 = DateTime.fromISO(DateTime.fromJSDate(date2).toUTC().toISODate());
    return data1 < data2;
  }

  static getDiferencaDatas(date1: Date, date2: Date): Duration {
    const data1 = DateTime.fromJSDate(date1).toUTC();
    const data2 = DateTime.fromJSDate(date2).toUTC();
    return data1.diff(data2, ['hours', 'minutes']);
  }

  static getUrlCORS(url: string, isLocal = false) {
    if (!isLocal) {
      return `https://cors.rxrweb.com.br:8045/${url}`;
    }
    return url;
  }
}
