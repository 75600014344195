
export const EthereumAssets = [
  {
    symbol: 'ETH',
    name: 'Ethereum',
    address: ''
  },
  {
    symbol: 'cUSDC',
    name: 'Compound USD Coin',
    address: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
    decimals: 8
  },
  {
    symbol: 'cETH',
    derivedSymbol: 'ETH',
    name: 'Compound Ether',
    address: '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
    decimals: 8
  },
  {
    symbol: 'USDT',
    name: 'Tether USD',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    decimals: 6
  }
];
