
export const CoinGeckoAssets = [
  {
    symbol: "wMEMO",
    name: "Wrapped MEMO",
    code: "wrapped-memory"
  },
  {
    symbol: "cUSDC",
    name: "Compound USDC",
    code: "compound-usd-coin"
  },
  {
    symbol: "RON",
    name: "Ronin",
    code: "ronin"
  },
  {
    symbol: 'BSGG',
    name: 'Betswap.gg',
    code: 'betswap-gg'
  },
  {
    symbol: 'AXS',
    name: 'Axie Infinity',
    code: 'axie-infinity'
  },
  {
    symbol: 'SLP',
    name: 'Smooth Love Potion',
    code: 'smooth-love-potion'
  },
  {
    symbol: 'GQ',
    name: 'Outer Ring',
    code: 'outer-ring'
  },
  {
    symbol: 'SAFUU',
    name: 'SAFUU',
    code: 'safuu'
  },
  {
    symbol: 'cETH',
    name: 'Compound Ether',
    code: 'compound-ether'
  }
];
