import { IBalance } from "../dtos/IBalance";
import { BinanceApi } from "../providers/BinanceApi";
import { Provider } from "../enums/Provider";
import { logger } from "../utils/Logger";
// import { FtxApi } from "../providers/FtxApi";
import { BinanceSmartChainApi } from "../providers/BinanceSmartChainApi";
import { AvalancheApi } from "../providers/AvalancheApi";
import { EthereumApi } from "../providers/EthereumApi";
import { RoninApi } from "../providers/RoninApi";
import { KatanaApi } from "../providers/KatanaApi";
import { ZkSyncApi } from "../providers/ZkSyncApi";
import { FantomApi } from "../providers/FantomApi";

export class BalanceService {
  private balances: IBalance[];
  private readonly binanceApi: BinanceApi;
  // private readonly ftxApi: FtxApi;
  private readonly binanceSmartChainApi: BinanceSmartChainApi;
  private readonly avalancheApi: AvalancheApi;
  private readonly ethereumApi: EthereumApi;
  private readonly roninApi: RoninApi;
  private readonly katanaApi: KatanaApi;
  private readonly zkSyncApi: ZkSyncApi;
  private readonly fantomApi: FantomApi;

  constructor() {
    this.binanceApi = new BinanceApi(this);
    // this.ftxApi = new FtxApi(this);
    this.binanceSmartChainApi = new BinanceSmartChainApi();
    this.avalancheApi = new AvalancheApi();
    this.ethereumApi = new EthereumApi();
    this.roninApi = new RoninApi();
    this.katanaApi = new KatanaApi(this.roninApi);
    this.zkSyncApi = new ZkSyncApi();
    this.fantomApi = new FantomApi();
    this.balances = [];
  }

  async updateBalances() {
    this.balances = [];

    await Promise.all([
      this.updateBalancesBinance(),
      // this.updateBalancesFtx(),
      this.updateBalancesBinanceSmartChain(),
      // this.updateBalancesAvalance(),
      this.updateBalancesEthereum(),
      // this.updateBalancesRonin(),
      // this.updateBalancesZkSync(),
      // this.updateBalancesFantom(),
      // this.updateBalancesStaking(),
    ]);
  }

  getAllBalances(provider?: Provider): IBalance[] {
    const listBalances = [];

    for (const item of this.balances) {
      if (!provider || item.provider === provider) {
        listBalances.push(item);
      }
    }

    return listBalances;
  }

  getBalance(provider: Provider, symbol: string): IBalance | undefined {
    return this.balances.find(p => p.provider === provider && p.symbol === symbol);
  }

  private async updateBalancesBinance() {
    try {
      const provider = Provider.BINANCE;
      const listBalances = await this.binanceApi.getAccountBalances();

      for (const item of listBalances) {
        const balance = Number(item.free);

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.asset,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos binance', error);
    }
  }

  private async updateBalancesBinanceSmartChain() {
    try {
      const provider = Provider.BINANCE_SMART_CHAIN;
      const listBalances = await this.binanceSmartChainApi.getAllBalances();

      for (const item of listBalances) {
        const balance = Number(item.balance);

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.derivedSymbol || item.symbol,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos binance smart chain', error);
    }
  }

  private async updateBalancesAvalance() {
    try {
      const provider = Provider.AVALANCHE;
      const listBalances = await this.avalancheApi.getAllBalances();

      for (const item of listBalances) {
        const balance = Number(item.balance);

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.symbol,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos avalanche', error);
    }
  }

  // private async updateBalancesFtx() {
  //   try {
  //     const provider = Provider.FTX;
  //     const listBalances = await this.ftxApi.getAccountBalances();

  //     for (const item of listBalances) {
  //       const balance = Number(item.free);

  //       if (balance > 0) {
  //         this.balances.push({
  //           provider,
  //           symbol: item.coin,
  //           balance,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     logger.error('Erro ao carregar saldos ftx', error);
  //   }
  // }

  private async updateBalancesEthereum() {
    try {
      const provider = Provider.ETHEREUM;
      const listBalances = await this.ethereumApi.getAllBalances();

      for (const item of listBalances) {
        const balance = Number(item.balance);

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.symbol,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos ethereum', error);
    }
  }

  private async updateBalancesRonin() {
    try {
      const provider = Provider.RONIN;
      const listBalances = await this.roninApi.getAllBalances();

      for (const item of listBalances) {
        const balance = Number(item.balance);

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.symbol,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos ronin', error);
    }
  }

  private async updateBalancesZkSync() {
    try {
      const provider = Provider.ZKSYNC;
      const listBalances = await this.zkSyncApi.getAllBalances();

      for (const item of listBalances) {
        const balance = item.balance;

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.symbol,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos zksync', error);
    }
  }

  private async updateBalancesFantom() {
    try {
      const provider = Provider.FANTOM;
      const listBalances = await this.fantomApi.getAllBalances();

      for (const item of listBalances) {
        const balance = item.balance;

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.derivedSymbol || item.symbol,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos fantom', error);
    }
  }

  private async updateBalancesStaking() {
    try {
      const provider = Provider.RONIN;
      const listBalances = await this.katanaApi.getAllBalances();

      for (const item of listBalances) {
        const balance = item.balance;

        if (balance > 0) {
          this.balances.push({
            provider,
            symbol: item.derivedSymbol || item.symbol,
            balance,
          });
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos staking', error);
    }
  }
}
