import { IDictionary } from "../dtos/IDictionary";
import { ITicker } from "../dtos/ITicker";
import { Provider } from "../enums/Provider";
import { BinanceApi } from "../providers/BinanceApi";
// import { FtxApi } from "../providers/FtxApi";
import { logger } from "../utils/Logger";

export class TickerService {
  private tickers24h: IDictionary<ITicker> = {};

  constructor(
    private readonly binanceApi: BinanceApi,
    // private readonly ftxApi: FtxApi,
  ) { }

  async updateTickers() {
    await Promise.all([
      this.updateTickersBinance(),
      // this.updateTickersFtx(),
    ]);

    // await this.updateTickersRonin();
  }

  async getTicker24h(symbol: string, provider?: Provider): Promise<ITicker> {
    if (provider) {
      const key = this.getKey(provider, symbol);
      return this.tickers24h[key];
    }

    return this.getTicker24hBySymbol(symbol);
  }

  getTicker24hBySymbol(symbol: string): ITicker {
    for (const key of Object.keys(this.tickers24h)) {
      const item = this.tickers24h[key];

      if (item.symbol === symbol) {
        return item;
      }
    }

    return {
      symbol,
      lastPrice: 0,
      priceChangePercent: 0
    }
  }

  getAllTickers24h(provider?: Provider): ITicker[] {
    const listTickers = [];

    for (const key of Object.keys(this.tickers24h)) {
      const item = this.tickers24h[key];

      if (!provider || item.provider === provider) {
        listTickers.push(item);
      }
    }

    return listTickers;
  }

  private async updateTickersBinance() {
    try {
      const provider = Provider.BINANCE;
      const listTickers = await this.binanceApi.getAllTickers24h();

      for (const item of listTickers) {
        const key = this.getKey(provider, item.symbol);

        this.tickers24h[key] = {
          provider,
          symbol: item.symbol,
          lastPrice: Number(item.lastPrice),
          priceChangePercent: Number(item.priceChangePercent),
        };
      }
    } catch (error) {
      logger.error('Erro ao carregar tickers binance', error);
    }
  }

  // private async updateTickersFtx() {
  //   try {
  //     const provider = Provider.FTX;
  //     const listTickers = await this.ftxApi.getAllTickers24h();

  //     for (const item of listTickers) {
  //       const key = this.getKey(provider, item.symbol);

  //       this.tickers24h[key] = {
  //         provider,
  //         symbol: item.symbol,
  //         lastPrice: item.lastPrice,
  //         priceChangePercent: item.priceChangePercent,
  //       };
  //     }
  //   } catch (error) {
  //     logger.error('Erro ao carregar tickers ftx', error);
  //   }
  // }

  private async updateTickersRonin() {
    try {
      const provider = Provider.RONIN;

      const ethTicker = await this.getTicker24h('ETHUSDT');

      const symbol = 'AXIEUSDT';
      const axiePriceETH = 0.005;
      const key = this.getKey(provider, symbol);

      this.tickers24h[key] = {
        provider,
        symbol: symbol,
        lastPrice: (axiePriceETH * ethTicker.lastPrice),
        priceChangePercent: 0,
      };

    } catch (error) {
      logger.error('Erro ao carregar tickers ronin', error);
    }
  }

  private getKey(provider: Provider, symbol: string): string {
    return `${provider.toString()}_${symbol}`;
  }
}
