import { Accordion, Card } from "react-bootstrap";
import { DashboardCardHeader } from "./DashboardCardHeader";
import { DashboardWallet } from "./DashboardWallet";

export const DashboardWalletCard = ({ eventKey, state, title }: any) => {
  if (state?.total?.totalUSD < 0.01) {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <DashboardCardHeader eventKey={eventKey} state={state} title={title} />
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <DashboardWallet key={eventKey} state={state} hideTotal={true} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
