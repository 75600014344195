
export const BinanceAssets = [
  {
    symbol: "AR",
    name: "Arweave"
  },
  {
    symbol: "SAND",
    name: "The Sandbox"
  },
  {
    symbol: "BADGER",
    name: "Badger DAO"
  },
  {
    symbol: "ETH",
    name: "Ethereum"
  },
  {
    symbol: "PERP",
    name: "Perpetual Protocol"
  },
  {
    symbol: "DOT",
    name: "Polkadot"
  },
  {
    symbol: "YGG",
    name: "Yield Guild Games"
  },
  {
    symbol: "LUNA",
    name: "Terra"
  },
  {
    symbol: "BNB",
    name: "Binance Coin"
  },
  {
    symbol: "USDT",
    name: "Tether",
    hide: false
  },
  {
    symbol: "GMT",
    name: "Green Metaverse Token"
  },
  {
    symbol: 'SOL',
    name: 'Solana'
  },
];
