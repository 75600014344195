
export enum Provider {
  BINANCE_SMART_CHAIN = 'BSC',
  BINANCE = 'Binance',
  AVALANCHE = 'Avalanche',
  FTX = 'FTX',
  ETHEREUM = 'Ethereum',
  RONIN = 'Ronin',
  ZKSYNC = 'zkSync',
  FANTOM = 'Fantom'
}
