import { AvalancheAssets } from "./AvalancheAssets";
import { BinanceAssets } from "./BinanceAssets";
import { BinanceSmartChainAssets } from "./BinanceSmartChainAssets";
import { CoinGeckoAssets } from "./CoinGeckoAssets";
import { EthereumAssets } from "./EthereumAssets";
import { FantomAssets } from "./FantomAssets";
import { FtxAssets } from "./FtxAssets";
import { RoninAssets } from "./RoninAssets";
import { ZkSyncAssets } from "./ZkSyncAssets";

export const AllAssets = [
  ...AvalancheAssets,
  ...BinanceAssets,
  ...BinanceSmartChainAssets,
  ...CoinGeckoAssets,
  ...EthereumAssets,
  ...FtxAssets,
  ...RoninAssets,
  ...ZkSyncAssets,
  ...FantomAssets,
];
