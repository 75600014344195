import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import { Utils } from '../../utils/Utils';

export const DashboardWallet = (props: any) => {
  const state = props?.state;

  if (!state?.balances) {
    return null;
  }

  const balancesSorted = (state.balances as any[]).sort((a, b) => b.totalUSD - a.totalUSD);
  const walletBalances = [];

  for (let i = 0; i < balancesSorted.length; i++) {
    const item = balancesSorted[i];
    const walletProviders = [];

    if (item.provider && Array.isArray(item.provider)) {
      const uniqueProviders = item.provider.filter((elem: any, pos: number) => {
        return item.provider.indexOf(elem) === pos;
      })

      for (const provider of uniqueProviders) {
        walletProviders.push(
          <Badge pill bg="primary">
            {provider}
          </Badge>
        );
      }
    }

    if (!item.hide) {
      walletBalances.push(
        <tr>
          <td className="tbl-col-rownumber">{i + 1}</td>
          <td className="tbl-col-symbol">{item.symbol}</td>
          <td className="tbl-col-name hidden lg:table-cell">{item.name}</td>
          <td className="tbl-col-balance">{Utils.valorFormatado(item.balance, 8)}</td>
          <td className="tbl-col-price">
            ${Utils.valorFormatado(item.price)}
            {item.priceChangePercent >= 0 ?
                <span className="ds-tag intent-success ml-4 minimal text-11">
                    <span className="left-icon">
                        <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 7H15V5h7v7h-2V8.414l-7 7-4-4-5.293 5.293a1 1 0 01-1.414-1.414L9 8.586l4 4L18.586 7z" fill=""></path></svg>
                    </span>
                    {Utils.valorFormatado(item.priceChangePercent)}%
                </span>
            :
                <span className="ds-tag intent-danger ml-4 minimal text-11">
                    <span className="left-icon">
                        <svg viewBox="0 0 24 24" width="16" height="16" className="fill-currentcolor"><path fillRule="evenodd" clipRule="evenodd" d="M18.586 15H15v2h7v-7h-2v3.586l-7-7-4 4-5.293-5.293a1 1 0 00-1.414 1.414L9 13.414l4-4L18.586 15z" fill=""></path></svg>
                    </span>
                    {Utils.valorFormatado(Math.abs(item.priceChangePercent))}%
                </span>
            }
          </td>
          <td className="tbl-col-totalusd">${Utils.valorFormatado(item.totalUSD)}</td>
          <td className="tbl-col-totalbrl">{Utils.valorEmReaisFormatado(item.totalBRL)}</td>
          {props.showParticipation ?
          <td className="tbl-col-percentualcarteira">{Utils.valorFormatado(item.percentParticipation)}%</td>
          : null}
          {props.showParticipation ?
          <td className="tbl-col-locais">{walletProviders}</td>
          : null}
        </tr>
      );
    }
  }

  return (
    <div className="px-16 mx-auto div-principal">
      {!props.hideTotal ?
      <div className="flex items-start flex-wrap justify-between mx-auto mt-8 mb-8">
        <div className="stats-widget">
          <div className="flex items-center flex-wrap lg:flex-nowrap">
            {state?.total ?
            <div className="rounded-8 p-20 bg-basic-1 relative overflow-hidden  w-full mb-20 lg:mb-0 lg:mr-20">
              <h5 className="text-basic-7 font-semibold">Total em BRL</h5>
              <img src="icon-money.svg" width="42" height="42" alt="" className="absolute select-none img-money-left" />
              <div className="relative">
                  <span className="text-24 leading-24 font-bold">
                    {Utils.valorEmReaisFormatado(state.total.totalBRL)}
                  </span>
              </div>
            </div>
            : null}
            {state?.total ?
            <div className="rounded-8 p-20 bg-basic-1 relative overflow-hidden  w-full mb-20 lg:mb-0">
              <h5 className="text-basic-7 font-semibold">Total em USD</h5>
              <img src="icon-money.svg" width="42" height="42" alt="" className="absolute select-none img-money-left" />
              <div className="relative">
                  <span className="text-24 leading-24 font-bold">
                    ${Utils.valorFormatado(state.total.totalUSD)}
                  </span>
              </div>
            </div>
            : null}
            </div>
          </div>
        </div>
        : null}
        <Table responsive hover>
          <thead>
            <tr>
              <th className="tbl-col-rownumber">#</th>
              <th className="tbl-col-symbol">Código</th>
              <th className="tbl-col-name hidden lg:table-cell">Nome</th>
              <th className="tbl-col-balance">Saldo</th>
              <th className="tbl-col-price">Preço</th>
              <th className="tbl-col-totalusd">Valor USD</th>
              <th className="tbl-col-totalbrl">Valor BRL</th>
              {props.showParticipation ?
              <th className="tbl-col-percentualcarteira">%</th>
              : null}
              {props.showParticipation ?
              <th className="tbl-col-locais">Locais</th>
              : null}
            </tr>
          </thead>
          <tbody>
            {walletBalances}
          </tbody>
        </Table>
      </div>
  );
};
