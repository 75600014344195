import { ZkSyncAssets } from "../assets/ZkSyncAssets";
import { logger } from "../utils/Logger";
import { ConfigurationService } from "../services/ConfigurationService";

export class ZkSyncApi {
	private readonly URL_API = 'https://api.zksync.io/api/v0.2';
	private readonly URL_ACCOUNTS = '/accounts/';
  private readonly DEFAULT_DECIMALS = 18;

  async getAllBalances(): Promise<any[]> {
    const balances = [];
    try {
      const addresses = ConfigurationService.getMetaMaskAddresses();

      if (addresses?.length > 0) {
        for (const address of addresses) {
          const addressBalances = await this.getBalancesFromAddress(address);
          balances.push(...addressBalances);
        }
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos zkSync', error);
    }

    return balances;
  }

  async getBalancesFromAddress(address: string): Promise<any[]> {
    const balances = [];
    try {
      if (address) {
        const listRequests = [];

        for (const asset of ZkSyncAssets) {
          const request = this.getBalanceOf(asset.symbol, address);
          listRequests.push(request);
        }

        const result = await Promise.all(listRequests);

        for (let i = 0; i < ZkSyncAssets.length; i++) {
          const token = ZkSyncAssets[i];
          const balance = result[i];

          balances.push({
            ...token,
            balance
          });
        }
      }
    } catch (error) {
      logger.error(`Erro ao carregar saldos zkSync para o endereço: ${address}`, error);
    }

    return balances;
  }

  async getBalanceOf(symbol: string, address: string, decimals = this.DEFAULT_DECIMALS): Promise<number> {
    const response = await this.getData(this.URL_ACCOUNTS + address);

    if (response?.result?.committed?.balances) {
      const valueDecimal = Number(response.result.committed.balances[symbol]);
      return valueDecimal / Math.pow(10, decimals);
    }
    
    return 0;
  }

  private async getData(endpoint: string): Promise<any> {
    const request = new Request(this.URL_API + endpoint, {
			method: 'GET',
		});

		return fetch(request)
			.then(response => {
				return response.json();
			});
  }
}
