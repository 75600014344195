import { useAccordionButton } from "react-bootstrap";
import { Utils } from "../../utils/Utils";

export const DashboardCardHeader = ({ eventKey, state, title }: any) => {
  const toggleAccordion = useAccordionButton(eventKey);
  return (
    <div className="flex items-start flex-wrap justify-between mx-auto mt-8 mb-8 cursor-pointer" onClick={toggleAccordion}>
      <div className="stats-widget">
        <div className="flex items-center flex-wrap lg:flex-nowrap">
        {state?.total ?
          <div className="rounded-8 p-20 bg-basic-1 relative overflow-hidden w-full mb-20 lg:mb-0 pl-80">
            <h5 className="text-basic-7 font-semibold">{title}</h5>
            <img src="icon-money.svg" width="42" height="42" alt="" className="absolute select-none img-money-left" />
            <div className="relative">
              <span className="text-20 leading-20 font-bold">
                {Utils.valorEmReaisFormatado(state.total.totalBRL)} ~ ${Utils.valorFormatado(state.total.totalUSD)}
              </span>
            </div>
          </div>
          : null}
        </div>
      </div>
    </div>
  );
};
