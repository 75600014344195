import { ITicker } from "../dtos/ITicker";
import { Utils } from "../utils/Utils";

export class CoinMarketCapApi {
  private readonly URL_API = 'https://pro-api.coinmarketcap.com/v2';
  private readonly URL_COIN_DATA = '/cryptocurrency/quotes/latest?symbol=';

  async getTicker24h(symbol: string, vsCurrency: string = 'USD'): Promise<ITicker> {
    const result = await this.getCoinData(symbol);
    const symbolData = result?.data[symbol] ? result.data[symbol][0] : null;

    return {
      symbol: symbol,
      name: symbolData?.name,
      lastPrice: symbolData?.quote[vsCurrency]?.price || 0,
      priceChangePercent: symbolData?.quote[vsCurrency]?.percent_change_24h || 0,
    };
  }

  async getCoinData(symbol: string) {
    return this.getData(this.URL_COIN_DATA + symbol);
  }

  private async getData(url: string): Promise<any> {
    const request = new Request(Utils.getUrlCORS(this.URL_API) + url, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json',
        'X-CMC_PRO_API_KEY': 'efd054cc-3a63-4305-8217-202f74001d5f',
			}),
		});

		return fetch(request)
			.then(response => {
				return response.json();
			});
  }
}
