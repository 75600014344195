import { EthereumAssets } from "../assets/EthereumAssets";
import { Constants } from "../enums/Constants";
import { EthereumRpcApi } from "./EthereumRpcApi";

export class EthereumApi extends EthereumRpcApi {

  constructor() {
    super(Constants.URL_RPC_ETHEREUM, EthereumAssets);
  }
}
