
export const AvalancheAssets = [
  {
    symbol: 'AVAX',
    name: 'Avalanche',
    address: ''
  },
  {
    symbol: 'wMEMO',
    name: 'Wrapped MEMO',
    address: '0x0da67235dd5787d67955420c84ca1cecd4e5bb3b'
  },
  {
    symbol: 'BSGG',
    name: 'Betswap.gg',
    address: '0x63682bdc5f875e9bf69e201550658492c9763f89'
  }
];
