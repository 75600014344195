import { BinanceSmartChainAssets } from "../assets/BinanceSmartChainAssets";
import { Constants } from "../enums/Constants";
import { logger } from "../utils/Logger";
import { Utils } from "../utils/Utils";
import { EthereumRpcApi } from "./EthereumRpcApi";
import { v4 as uuidv4 } from "uuid";

export class BinanceSmartChainApi extends EthereumRpcApi {

  private readonly RANGE_VAULT_BALANCE = {start: 0, end: 66};

  constructor() {
    super(Constants.URL_RPC_BINANCE_SMART_CHAIN, BinanceSmartChainAssets);
  }

  async getBalancesFromAddress(address: string): Promise<any[]> {
    const balances = [];
    try {
      if (address) {
        const listRequests = [];

        for (const asset of this.assets) {
          let request = null;

          if (asset.address && !asset.vault) {
            request = this.getBalanceOf(asset.address, address, asset.decimals);
          } else if (asset.address && asset.vault) {
            request = this.getVaultBalanceOf(asset.address, address, asset.decimals);
          } else {
            request = this.getBalance(address);
          }

          listRequests.push(request);
        }

        const result = await Promise.all(listRequests);

        for (let i = 0; i < this.assets.length; i++) {
          const token = this.assets[i];
          const balance = result[i];

          balances.push({
            ...token,
            balance
          });
        }
      }
    } catch (error) {
      logger.error(`Erro ao carregar saldos para o endereço: ${address}`, error);
    }

    return balances;
  }

  async getVaultBalanceOf(contractAddress: string, address: string, decimals?: number): Promise<number> {
    const payload = this.buildCallVaultBalanceOf(contractAddress, address);
    const response = await this.postData(payload);
    const balanceHex = response.result.substring(this.RANGE_VAULT_BALANCE.start, this.RANGE_VAULT_BALANCE.end);
    return Utils.getNumberFromHex(balanceHex, decimals);
  }

  private buildCallVaultBalanceOf(contractAddress: string, address: string): string {
    return JSON.stringify({
      id: uuidv4(),
      jsonrpc: '2.0',
      method: 'eth_call',
      params: [
        {
          from: address,
          to: '0x9e2aaf377d1dbe8db493e2084385f03e94d5d3b9',
          data: `0x757d2331000000000000000000000000${contractAddress.replace('0x', '')}`
        },
        'latest'
      ]
    });
  }
}
