import { ILogObject } from "tslog";
import { LoggerTransportBase } from "./LoggerTransportBase";

export class LoggerTransportConsole extends LoggerTransportBase {
    save(logObject: ILogObject): void {
        const minLevelToStdErr = 4;
        const message = this.instance.getPrettyLog(logObject);

        if (logObject.logLevelId < minLevelToStdErr) {
          console.log(message);
        } else {
          console.error(message);
        }
    }

    async wait() {
      return Promise.resolve();
    }
}
