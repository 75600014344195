import { AvalancheAssets } from "../assets/AvalancheAssets";
import { Constants } from "../enums/Constants";
import { EthereumRpcApi } from "./EthereumRpcApi";

export class AvalancheApi extends EthereumRpcApi {

  constructor() {
    super(Constants.URL_RPC_AVALANCHE, AvalancheAssets);
  }
}
