
export const BinanceSmartChainAssets = [
  {
    symbol: 'BNB',
    name: 'Binance Coin',
    address: ''
  },
  {
    symbol: 'BTC',
    name: 'Bitcoin',
    address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c'
  },
  {
    symbol: 'ETH',
    name: 'Ethereum',
    address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8'
  },
  {
    symbol: 'ALICE',
    name: 'ALICE',
    address: '0xac51066d7bec65dc4589368da368b212745d63e8',
    decimals: 6
  },
  {
    symbol: 'AAVE',
    name: 'Aave',
    address: '0xfb6115445bff7b52feb98650c87f44907e58f802'
  },
  {
    symbol: 'ADA',
    name: 'Cardano',
    address: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47'
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    address: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1'
  },
  {
    symbol: 'AVAX',
    name: 'Avalanche',
    address: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041'
  },
  {
    symbol: 'ALPHA',
    name: 'Alpha',
    address: '0xa1faa113cbe53436df28ff0aee54275c13b40975'
  },
  {
    symbol: 'DOGE',
    name: 'Dogecoin',
    address: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    decimals: 8
  },
  {
    symbol: 'CAKE',
    name: 'PancakeSwap',
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82'
  },
  {
    symbol: 'SOL',
    name: 'Solana',
    address: '0x570a5d26f7765ecb712c0924e4de545b89fd43df'
  },
  {
    symbol: 'GQ',
    name: 'Outer Ring',
    address: '0xf700d4c708c2be1463e355f337603183d20e0808'
  },
  {
    symbol: 'BUSD',
    name: 'BUSD',
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56'
  },
  {
    symbol: 'SAFUU',
    name: 'SAFUU',
    address: '0xe5ba47fd94cb645ba4119222e34fb33f59c7cd90',
    decimals: 5
  }
];
