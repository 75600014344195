import { logger } from "../utils/Logger";
import { Utils } from "../utils/Utils";
import { ConfigurationService } from "../services/ConfigurationService";
import { RoninApi } from "./RoninApi";
import { RoninAssets } from "../assets/RoninAssets";

export class KatanaApi {
  private readonly DECIMALS = 18;
  private readonly URL_API = 'https://thegraph.roninchain.com/subgraphs/name/axieinfinity/katana-subgraph-blue';

  constructor(
    private readonly roninApi: RoninApi,
  ) {

  }

  async getStakingDashboard(): Promise<any> {
    try {
      const postCallLiquidityPositions = this.buildCallLiquidityPositions();
      const response = await this.postData(postCallLiquidityPositions);

      if (response?.data) {
        const position = response?.data.liquidityPositions[0];

        // const postCallPairs = this.buildCallPairs();
        // const responsePairs = await this.postData(postCallPairs);
        // console.log('responsePairs:', responsePairs);

        const myLastClaimBlockNumber = await this.roninApi.getLastClaimBlockLP();
        const myLastClaimBlock = await this.roninApi.getBlockByNumber(myLastClaimBlockNumber);
        const myLastClaimBlockTimestamp = Utils.getValueFromHex(myLastClaimBlock.result.timestamp);
        const myLastClaimBlockDate = new Date(myLastClaimBlockTimestamp * 1000);

        const actualDate = new Date();
        const myNextClaimDate = Utils.getDataAddDias(myLastClaimBlockDate, 1);
        const isClaimAvailable = Utils.isDataHoraMaior(actualDate, myNextClaimDate);
        const nextClaimDuration = Utils.getDiferencaDatas(actualDate, myNextClaimDate);

        const myTotalStaked = Number(position.liquidityTokenBalance);
        const myPendingRewards = await this.roninApi.getPendingRewardsLP();
        const myWalletBalance = 0; //Utils.getNumberFromHex(myWalletBalanceHex);
        const totalStaked = Number(position.pair.totalSupply);
        const totalStakedUSD = Number(position.pair.reserveUSD);
        const myTotal = myTotalStaked + myWalletBalance;
        const myParticipation = myTotalStaked / totalStaked;
        const myParticipationToken0 = myParticipation * Number(position.pair.reserve0);
        const myParticipationToken1 = myParticipation * Number(position.pair.reserve1);

        return {
          isClaimAvailable: isClaimAvailable,
          nextClaimDuration,
          myNextClaimDate,
          myLastClaimBlock,
          myTotalStaked,
          myPendingRewards,
          myWalletBalance,
          totalStaked,
          totalStakedUSD,
          myTotal,
          myParticipation,
          myParticipationToken0,
          myParticipationToken1,
        };
      }
    } catch (error) {
      logger.error('Erro ao carregar dashboard katana', error);
    }

    return null;
  }

  async getAllBalances(): Promise<any[]> {
    const balances = [];
    try {
      const address = ConfigurationService.getRoninAddress();

      if (!address) {
        return [];
      }

      const postCallLiquidityPositions = this.buildCallLiquidityPositions();
      const response = await this.postData(postCallLiquidityPositions);

      if (response?.data) {
        const position = response?.data.liquidityPositions[0];

        const myTotalStaked = Number(position.liquidityTokenBalance);
        const totalSupply = Number(position.pair.totalSupply);
        const myParticipation = myTotalStaked / totalSupply;
        const myParticipationToken0 = myParticipation * Number(position.pair.reserve0);
        const myParticipationToken1 = myParticipation * Number(position.pair.reserve1);

        const assetToken0 = RoninAssets.find(p => p.symbol === position.pair.token0.symbol);
        const assetToken1 = RoninAssets.find(p => p.symbol === position.pair.token1.symbol);

        balances.push({
          ...assetToken0,
          balance: myParticipationToken0
        });

        balances.push({
          ...assetToken1,
          balance: myParticipationToken1
        });
      }
    } catch (error) {
      logger.error('Erro ao carregar saldos katana', error);
    }

    return balances;
  }

  private buildCallLiquidityPositions(): string {
    const address = ConfigurationService.getRoninAddress();
    return `{"operationName":"liquidityPositions","variables":{"user":"0x${address}"},"query":"query liquidityPositions($user: Bytes!) {  liquidityPositions(where: {user: $user}) {\\n    pair {\\n      id\\n      reserve0\\n      reserve1\\n      reserveUSD\\n      token0 {\\n        id\\n        symbol\\n        derivedETH\\n        __typename\\n      }\\n      token1 {\\n        id\\n        symbol\\n        derivedETH\\n        __typename\\n      }\\n      totalSupply\\n      __typename\\n    }\\n    liquidityTokenBalance\\n    __typename\\n  }\\n}\\n"}`;
  }

  private buildCallPairs(): string {
    const allPairs = ['0x2ecb08f87f075b5769fe543d0e52e40140575ea7','0xa7964991f339668107e2b6a6f6b8e8b74aa9d017'];
    return `{"query":"query pairs($allPairs: [Bytes]!) {\\n  pairs(where: {\\n    id_in: $allPairs\\n  }) {\\n    id\\n    token0 {\\n      symbol\\n    }\\n    token1 {\\n      symbol      \\n    }\\n    token0Price\\n    token1Price\\n  }\\n}","variables":{"allPairs":${JSON.stringify(allPairs)}},"operationName":"pairs"}`;
  }

  private async postData(data: any): Promise<any> {
    const request = new Request(Utils.getUrlCORS(this.URL_API), {
			method: 'POST',
			body: data,
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
		});

		return fetch(request)
			.then(response => {
				return response.json();
			});
  }
}
