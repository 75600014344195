import Rollbar from "rollbar";
import { LoggerTransportBase } from "./LoggerTransportBase";
import { cLogger } from "./Logger";
import { ILogObject } from "tslog";

export class LoggerTransportRollbar extends LoggerTransportBase {
    private rollbar: Rollbar;

    constructor(instance: cLogger, options: any) {
        super(instance, options);
        this.rollbar = new Rollbar(this.options);
    }

    save(logObject: ILogObject): void {
        const message = this.instance.getPrettyLog(logObject, true, true);

        switch (logObject.logLevel) {
            case "silly":
            case "trace":
            case "debug":
                this.rollbar.debug(message);
                break;
            case "info":
                this.rollbar.info(message);
                break;
            case "warn":
                this.rollbar.warning(message);
                break;
            case "error":
                this.rollbar.error(message);
                break;
            case "fatal":
                this.rollbar.critical(message);
                break;
            default:
                this.rollbar.debug(message);
                break;
        }
    }

    async wait() {
      const awaiter = new Promise((resolve: any) => {
        this.rollbar.wait(resolve);
      });
      await awaiter;
    }
}
