import { ITicker } from "../dtos/ITicker";
import { CoinGeckoAssets } from "../assets/CoinGeckoAssets";
import { Utils } from "../utils/Utils";
import { logger } from "../utils/Logger";

export class CoinGeckoApi {
  private readonly URL_API = 'https://api.coingecko.com/api/v3';
  private readonly URL_COIN_DATA = '/coins/';

  async getTicker24h(symbol: string, vsCurrency: string = 'usd'): Promise<ITicker> {
    try {
      const coinId = this.getCoinIdFromSymbol(symbol);

      if (coinId) {
        const data = await this.getCoinData(coinId);

        return {
          symbol: symbol,
          name: data?.name,
          lastPrice: data?.market_data?.current_price[vsCurrency] || 0,
          priceChangePercent: data?.market_data?.price_change_percentage_24h_in_currency[vsCurrency] || 0,
        };
      }
    } catch (error) {
      logger.error('Erro ao carregar ticker coingecko', error);
    }

    return {
      symbol: symbol,
      lastPrice: 0,
      priceChangePercent: 0
    }
  }

  async getCoinData(id: string) {
    return this.getData(this.URL_COIN_DATA + id);
  }

  private getCoinIdFromSymbol(symbol: string): string {
    for (const item of CoinGeckoAssets) {
      if (item.symbol === symbol) {
        return item.code;
      }
    }

    return "";
  }

  private async getData(url: string): Promise<any> {
    const request = new Request(Utils.getUrlCORS(this.URL_API) + url, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
		});

		return fetch(request)
			.then(response => {
				return response.json();
			});
  }
}
