import { cLogger } from "../log/Logger";

export const logger = cLogger.createLogger({
	name: 'crypto-invest',
	suppressStdOutput: true,
	transports: [
		{
			provider: 'console',
			minLevel: process.env.REACT_APP_LOGGER_CONSOLE_MIN_LEVEL,
			enabled: true,
		},
		{
			provider: 'rollbar',
			minLevel: process.env.REACT_APP_LOGGER_ROLLBAR_MIN_LEVEL,
			enabled: process.env.REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN ? true : false,
			options: {
				accessToken: process.env.REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN,
				environment: process.env.REACT_APP_ENV,
				codeVersion: '',
			},
		},
	],
});

window.onerror = function (message, source, lineno, colno, error) {
	logger.error(message, error);
};
